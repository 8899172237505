import ReqCounselingForm from "../ReqCounselingForm";
import ModalBase from "./ModalBase";

export default function ReqCounsleingModal() {
	return (
		<ModalBase listener="openReqCounselingModal" title="درخواست مشاوره">
			<ReqCounselingForm></ReqCounselingForm>
		</ModalBase>
	);
}
