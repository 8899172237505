import { ProjectCard, BtnPrimary } from "../../components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { useRef } from "react";
import ProjectDetailsModal from "../shared/modals/ProjectDetailsModal";
import EventEmitter from "../../utils/EventEmitter";

export default function PortfolioSlider({ items }) {
	const dotsCount = 3;

	const sliderRef = useRef();
	const sliderOptions = {
		direction: "rtl",
		type: "loop",
		gap: "30px",
		perPage: 2,
		perMove: 1,
		arrows: false,
		pagination: false,
		breakpoints: {
			1140: {
				perPage: 1,
			},
		},
	};

	function renderActiveDot(activeIndex, totalIndex) {
		// calculate the active dot
		let activeDot = Math.round(
			((activeIndex + 1) / (totalIndex + 1)) * dotsCount
		);

		// prevent invalid active dot
		if (activeDot <= 0) {
			activeDot = 1;
		}

		//get dots and give active class to the active dot
		const dots = document.querySelectorAll(
			".portfolio-slider-wrapper .slider-dot"
		);
		dots.forEach((dot, index) => {
			if (index === activeDot - 1) {
				dot.classList.add("active");
			} else {
				dot.classList.remove("active");
			}
		});
	}

	return (
		<div className="portfolio-slider-wrapper">
			<ProjectDetailsModal />

			<Splide
				options={sliderOptions}
				ref={sliderRef}
				onMounted={(slider) => {
					const totalIndex = slider.Components.Controller.getEnd();
					const activeIndex = slider.Components.Controller.getIndex();
					renderActiveDot(activeIndex, totalIndex);
				}}
				onMove={(slider) => {
					const totalIndex = slider.Components.Controller.getEnd();
					const activeIndex = slider.Components.Controller.getIndex();
					renderActiveDot(activeIndex, totalIndex);
				}}
			>
				{items
					.concat(items)
					.concat(items)
					.map((item, index) => (
						<SplideSlide key={index}>
							<div className="our-services-item">
								<ProjectCard
									title={item.title}
									properties={item.properties}
									outlineBtnText="مشاهده جزئیات"
									onOutlineBtnClick={() => {
										EventEmitter.emit("openProjectDetailsModal");
									}}
								/>
							</div>
						</SplideSlide>
					))}
			</Splide>

			<div className="slider-footer">
				<div className="slider-footer-start">
					<div className="slider-btns">
						<BtnPrimary
							className="slider-btn-prev"
							iconOnly={"isax isax-arrow-right-1"}
							outline
							onClick={(e) =>
								e.stopPropagation() ||
								sliderRef.current?.go("<")
							}
						/>

						<div className="slider-dots" style={{ minWidth: 55 }}>
							{[...Array(dotsCount)].map((_, index) => (
								<div className={`slider-dot`} key={index}></div>
							))}
						</div>

						<BtnPrimary
							className="slider-btn-next"
							iconOnly={"isax isax-arrow-left"}
							outline
							onClick={(e) =>
								e.stopPropagation() ||
								sliderRef.current?.go(">")
							}
						/>
					</div>
				</div>

				<div className="slider-footer-end">
					<BtnPrimary className="view-all-btn" text>
						همه نمونه کار ها
						<i
							className="isax isax-arrow-left"
							style={{ marginRight: 10, verticalAlign: "middle" }}
						/>
					</BtnPrimary>
				</div>
			</div>
		</div>
	);
}
