import "../../styles/pages/about/_hero-section-1.scss";

export default function HeroSection1() {
	return (
		<div className="hero-section-1">
			<div className="hero-content-wrap content-wrapper-lg">
				<div className="hero-title-mobile">
					<div className="hero-top-title">درباره ما</div>
					<h2 className="hero-title">به Rexomin خوش آمدید!</h2>
				</div>

				<div className="hero-content">
					<div className="hero-title-desktop">
						<div className="hero-top-title">درباره ما</div>
						<h2 className="hero-title">به Rexomin خوش آمدید!</h2>
					</div>

					<p className="hero-text">
						شرکت برتر توسعه نرم‌افزار که متعهد است تا با ارائه
						راهکارهای نوآورانه، به کسب‌وکارها کمک کند تا رشد و
						موفقیتی بی‌سابقه را تجربه کنند. از زمان تأسیس در سال
						1402، ما در حال توانمندسازی سازمان‌ها در تمامی صنایع
						هستیم تا از تکنولوژی بهره‌برده و به رشد بیاندیشند.
						<br />
						<br />
						در Rexomin، ما اعتقاد داریم که نرم‌افزار قدرتی برای تحول
						کسب‌وکارها دارد و هدف ما استفاده از این قدرت است تا به
						مشتریانمان راهکارهای نوینی را با توجه به نیازهای خاص
						آنها ارائه دهیم.
					</p>
				</div>

				<div className="hero-pic">
					<img
						src={require("../../assets/pics/graphic-6.png")}
						alt="hero"
						width="540"
						height="417"
					/>
				</div>
			</div>
		</div>
	);
}
