// import styles
import "../styles/components/_btns.scss";
import Loader from "./Loader";

export default function BtnWithIcon({
	children,
	icon,
	loading = false,
	...props
}) {
	// pass component className
	let className = "rx-btn-with-icon";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<button {...props} className={className}>
			<div className="btn-content">{children}</div>

			<div className="btn-icon">
				{loading && (
					<div className="btn-loader">
						<Loader
							size={20}
							thickness={2}
							color="var(--secondary-color)"
						/>
					</div>
				)}

				<span
					className={
						"btn-icon-content" + (loading ? " btn-loading" : "")
					}
				>
					<i className={icon}></i>
				</span>
			</div>
		</button>
	);
}
