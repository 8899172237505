import ProjectAcceptanceSlider from "./ProjectAcceptanceSlider";
import "../../styles/pages/home/_project-acceptance-section.scss";

export default function ProjectAcceptanceSection() {
	const benefits = [
		{
			icon: "isax isax-document-text",
			title: "۱. تعریف و بررسی نیازمندی‌ها:",
			description:
				"در اولین مرحله، با مشتری صحبت کرده و نیازمندی‌های پروژه را به دقت تعیین میکنیم. این شامل قابلیت‌ها، طراحی، عملکرد و هدف‌های پروژه است. ما اطلاعاتی را که ارائه کرده‌اید به دقت بررسی می‌کنیم تا پروژه و اهداف آن را کاملاً درک کنیم.",
		},
		{
			icon: "isax isax-presention-chart",
			title: "۲. تحلیل:",
			description:
				"پس از تعریف نیازمندی‌ها، ما نیازهای کلی پروژه را بررسی می‌کنیم و تحلیلی عمیق‌تر انجام می‌دهیم. این مرحله شامل تعیین وظایف و مسئولیت‌ها، برنامه زمانی و ساختار پروژه ماست.",
		},
		{
			icon: "isax isax-pen-tool-2",
			title: "۳. طراحی:",
			description:
				"در این مرحله، طرح کلی سایت را طراحی می‌کنیم. شامل طراحی رابط کاربری (UI)، نمایش داده‌ها و ساختار صفحات است.",
		},
		{
			icon: "isax isax-document-code",
			title: "۴. توسعه:",
			description:
				"در این مرحله، طرح کلی سایت را طراحی می‌کنیم. شامل طراحی رابط در این مرحله، متخصصان ما طراحی انجام شده در مرحله قبل را به کمک دانش برنامه نویسی به یک برنامه کاربردی تبدیل میکنند.",
		},
		{
			icon: "isax isax-graph",
			title: "۵. تست و ارزیابی:",
			description:
			"پس از توسعه، پروژه را تست میکنیم تا از عملکرد صحیح آن اطمینان حاصل شود. تست‌ها می‌توانند شامل تست واحد (Unit test)، تست عملکرد (Performance testing)، تست نفوذ (penetration test) و تست کاربردی باشند.",
		},
		{
			icon: "isax isax-driver-2",
			title: "۶. راه‌اندازی و استقرار:",
			description:
				"در مرحله بعد، پروژه را در سرور یا محیط زنده قرار داده و آماده بهره‌برداری میکنیم.",
		},
		{
			icon: "isax isax-cloud-add",
			title: "۷. پشتیبانی و نگهداری",
			description:
				"پس از راه‌اندازی، در صورت نیاز مشتری سایت را پشتیبانی کرده و به نگهداری آن میپردازیم. این شامل رفع اشکالات، به‌روزرسانی امنیتی و اضافه کردن قابلیت‌های جدید است.",
		},
	];

	return (
		<div className="project-acceptance-section">
			<div className="project-acceptance-header-wrap content-wrapper-lg">
				<div className="project-acceptance-header">
					<h2 className="project-acceptance-title">
						<span className="bold-text">فرایند قبول</span> و انجام
						پروژه ها
					</h2>
				</div>
			</div>

			<div className="project-acceptance-content content-wrapper-lg no-transition">
				<ProjectAcceptanceSlider items={benefits} />
			</div>
		</div>
	);
}
