import { ProjectCard } from "../../components";

import "../../styles/pages/portfolio/_portfolio-grid.scss";
import EventEmitter from "../../utils/EventEmitter";
import ProjectDetailsModal from "../shared/modals/ProjectDetailsModal";

export default function PortfolioGrid({items}) {
	return (
		<div className="portfolio-grid">
            <ProjectDetailsModal />

            <div className="portfolio-grid-content content-wrapper-lg">
                <div className="grid-row">
                    {items.concat(items).concat(items).map((item, index) => (
                        <div className="grid-col" key={index}>
                            <ProjectCard
                                title={item.title}
                                properties={item.properties}
                                outlineBtnText="مشاهده جزئیات"
                                onOutlineBtnClick={() => {
                                    EventEmitter.emit("openProjectDetailsModal");
                                }}
                            />
                            </div>
                    ))}
                </div>
            </div>
        </div>
	);
}
