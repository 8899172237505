import BtnPrimary from "../../components/BtnPrimary";
import { NavLink } from "react-router-dom";
import { DecorationSpinner } from "../../components";

import "../../styles/layout/_footer.scss";
import EventEmitter from "../../utils/EventEmitter";

export default function Footer({ ...props }) {
	const navItems = [
		{
			title: "نمونه کار ها",
			link: "/portfolio",
		},
		{ title: "درباره ما", link: "/about" },
		{ title: "تماس با ما", link: "/contact-us" },
	];

	return (
		<div className="rx-footer">
			<div className="footer-content content-wrapper-lg">
				<div className="footer-top">
					<div className="footer-top-start">
						<div className="footer-logo">
							<NavLink to="/">
								<img
									src={
										require("../../assets/pics/logo-light.svg")
											.default
									}
									alt="company logo"
									width="116"
									height="22"
								/>
							</NavLink>
						</div>

						<div className="footer-vertical-divider"></div>

						<div className="footer-slogan">
							<p>همه چیز را به ما بسپارید!</p>
						</div>
					</div>

					<div className="footer-top-end">
						<BtnPrimary className="req-counseling-btn" onClick={() => EventEmitter.emit("openReqCounselingModal")}>
							<i
								className="isax isax-edit-2"
								style={{
									verticalAlign: "middle",
									marginLeft: "4px",
								}}
							></i>
							درخواست مشاوره
						</BtnPrimary>
					</div>
				</div>

				<div className="footer-contact-info">
					<ul className="footer-contact-info-list">
						<li>
							<div className="contact-info-icon">
								<i className="isax isax-sms"></i>
							</div>
							<div className="contact-info-text">
								<p>support@rexomin.com</p>
							</div>
						</li>
						<li>
							<div className="contact-info-icon">
								<i className="isax isax-call"></i>
							</div>
							<div className="contact-info-text">
								<p>+989339807135</p>
							</div>
						</li>
						<li>
							<div className="contact-info-icon">
								<i className="isax isax-call"></i>
							</div>
							<div className="contact-info-text">
								<p>+989339807135</p>
							</div>
						</li>
					</ul>
				</div>

				<div className="footer-bottom">
					<div className="footer-bottom-start">
						<nav className="footer-menu">
							<ul>
								{navItems.map((item, index) => (
									<li key={index}>
										<NavLink
											className="footer-link"
											to={item.link}
										>
											{item.title}
										</NavLink>
									</li>
								))}
							</ul>
						</nav>
					</div>

					<div className="footer-bottom-end">
						<div className="footer-social">
							<ul>
								<li>
									<a href="/">
										<i className="isax isax-instagram"></i>
									</a>
								</li>
								<li>
									<a href="/">
										<i className="isax isax-instagram"></i>
									</a>
								</li>
								<li>
									<a href="/">
										<i className="isax isax-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-copyright">
				<div className="copyright-decoration">
					<DecorationSpinner className="dec" />
				</div>
				<p>
					کلیه حقوق مادی و معنوی این سایت محفوظ و هرگونه کپی برداری از
					آن پیگرد قانونی دارد.
				</p>
			</div>
		</div>
	);
}
