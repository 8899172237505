import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css"; // Import Splide styles
import React, { useEffect, useRef } from "react";
import "../../../styles/pages/shared/projectDetails/_project-gallery.scss";

function generateSlides(length = 7, sig = 0) {
	return Array.from({ length }).map((value, index) => {
		index = sig || index;

		return {
			src: require(`../../../assets/pics/gallery/0${index + 1}.jpg`),
			alt: `Image ${index + 1}`,
		};
	});
}

export default function ProjectGallery() {
	const mainRef = useRef(null);
	const thumbsRef = useRef(null);

	useEffect(() => {
		if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
			mainRef.current.sync(thumbsRef.current.splide);
		}
	}, []);

	const renderSlides = () => {
		return generateSlides().map((slide) => (
			<SplideSlide key={slide.src} className="splide-slide">
				<img src={slide.src} alt={slide.alt} style={{
                    width: "100%",
					height: "100%",
                    objectFit: "cover",
                }} />
			</SplideSlide>
		));
	};

	const mainOptions = {
		type: "loop",
		perPage: 1,
		perMove: 1,
		gap: "1rem",
		arrows: false,
		pagination: false,
		height: "460px",
        direction: "rtl",
        breakpoints: {
            768: {
                height: "200px",
            },
        },
	};

	const thumbsOptions = {
		type: "loop",
		/* rewind: true, */
		gap: "1rem",
		pagination: false,
		fixedWidth: 110,
		fixedHeight: 70,
		cover: true,
		focus: "center",
		isNavigation: true,
        direction: "rtl",
	};

	return (
		<div className="project-gallery-slider-wrapper">
			<Splide
				options={mainOptions}
				ref={mainRef}
                className="project-gallery-slider-main"
			>
				{renderSlides()}
			</Splide>

			<Splide
				options={thumbsOptions}
				ref={thumbsRef}
                className="project-gallery-slider-thumbs"
			>
				{renderSlides()}
			</Splide>
		</div>
	);
}