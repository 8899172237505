// import styles
import "../styles/components/_project-acceptance-card.scss";

export default function ProjectAcceptanceCard({
	icon,
	title,
	description,
	...props
}) {
	// pass component className
	let className = "rx-project-acceptance-card-wrap";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="project-acceptance-card">
				<div className="project-acceptance-card-icon-wrap">
					<i className={icon}></i>
				</div>
				<div className="project-acceptance-card-content">
					<h3 className="project-acceptance-card-title">
						{title}
					</h3>
					<p className="project-acceptance-card-description">
						{description}
					</p>
				</div>
			</div>
		</div>
	);
}
