import BtnPrimary from "../../components/BtnPrimary";
import "../../styles/layout/_header.scss";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import EventEmitter from "../../utils/EventEmitter";

export default function Header({ ...props }) {
	const mobileSidebar = useRef(null);
	const mobileSidebarBackdrop = useRef(null);

	function toggleMobileSidebar() {
		mobileSidebarBackdrop.current.classList.toggle("active");
		mobileSidebar.current.classList.toggle("active");
	}

	function closeMobileSidebar() {
		mobileSidebarBackdrop.current.classList.remove("active");
		mobileSidebar.current.classList.remove("active");
	}

	const navItems = [
		{ title: "صفحه اصلی", link: "/", icon: "isax isax-home" },
		{
			title: "نمونه کار ها",
			link: "/portfolio",
			icon: "isax isax-monitor",
		},
		{ title: "درباره ما", link: "/about", icon: "isax isax-user" },
		{ title: "تماس با ما", link: "/contact-us", icon: "isax isax-call" },
	];

	return (
		<>
			<div className="rx-header">
				<div className="header-content content-wrapper-lg">
					<div className="header-start">
						<div className="header-logo">
							<NavLink to="/">
								<img
									src={
										require("../../assets/pics/logo.svg")
											.default
									}
									alt="company logo"
									width="116"
									height="22"
								/>
							</NavLink>
						</div>

						<nav className="header-menu">
							<ul>
								{navItems.map((item, index) => (
									<li key={index}>
										<NavLink
											className="header-link"
											to={item.link}
										>
											{item.title}
										</NavLink>
									</li>
								))}
							</ul>
						</nav>
					</div>

					<div className="header-end">
						<BtnPrimary
							className="req-counseling-btn"
							neutral
							onClick={() =>
								EventEmitter.emit("openReqCounselingModal")
							}
						>
							<i
								className="isax isax-edit-2"
								style={{
									verticalAlign: "middle",
									marginLeft: "4px",
								}}
							></i>
							درخواست مشاوره
						</BtnPrimary>

						<BtnPrimary
							className="open-sidebar-btn"
							neutral
							onClick={toggleMobileSidebar}
						>
							<img
								src={
									require("../../assets/pics/menu.svg")
										.default
								}
								alt="menu"
								width="24"
								height="24"
							/>
						</BtnPrimary>
					</div>
				</div>

				<div
					className="mobile-sidebar-backdrop"
					onClick={toggleMobileSidebar}
					ref={mobileSidebarBackdrop}
				/>

				<div className="mobile-sidebar" ref={mobileSidebar}>
					<button
						className="mobile-sidebar-close-btn"
						onClick={closeMobileSidebar}
					>
						<i
							className="isax isax-arrow-right-3"
							style={{
								verticalAlign: "middle",
								marginLeft: "4px",
							}}
						/>
					</button>

					<div className="mobile-sidebar-content">
						<div className="mobile-sidebar-header">
							<div className="mobile-sidebar-logo">
								<img
									src={
										require("../../assets/pics/logo.svg")
											.default
									}
									alt="company logo"
									width="116"
									height="22"
								/>
							</div>
						</div>

						<nav className="mobile-sidebar-menu">
							<ul>
								{navItems.map((item, index) => (
									<li key={index}>
										{/* <a href={item.link}>
										<i className={item.icon}></i>
										{item.title}
									</a> */}
										<NavLink
											to={item.link}
											onClick={closeMobileSidebar}
										>
											<i className={item.icon}></i>
											{item.title}
										</NavLink>
									</li>
								))}
							</ul>
						</nav>

						<div className="mobile-sidebar-footer">
							<BtnPrimary
								className="req-counseling-btn"
								neutral
								onClick={() => {
									closeMobileSidebar();
									EventEmitter.emit("openReqCounselingModal");
								}}
							>
								<i
									className="isax isax-edit-2"
									style={{
										verticalAlign: "middle",
										marginLeft: "4px",
									}}
								></i>
								درخواست مشاوره
							</BtnPrimary>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
