import { BtnPrimary } from "../../../components";
import "../../../styles/pages/shared/projectDetails/_index.scss";
import ProjectGallery from "./ProjectGallery";

export default function ProjectDetails({ ...props }) {
	return (
		<div
			{...props}
			className={
				"project-details" +
				(props.className ? " " + props.className : "")
			}
		>
			<div className="sections">
				<div className="details-section">
					<div className="section-title-wrap">
						<i className="section-icon isax isax-note-21"></i>
						<h3 className="section-title">بریف پروژه</h3>
					</div>
					<div className="section-content">
						<p className="section-text">
							لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
							صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
							متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
							است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
							متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای
							زیادی در شصت و سه درصد گذشته حال و آینده شناخت
							فراوان جامعه و متخصصان را می طلبد
						</p>
					</div>
				</div>

				<div className="details-section">
					<div className="section-title-wrap">
						<i className="section-icon isax isax-document-text"></i>
						<h3 className="section-title">توضیحات رکسومین</h3>
					</div>
					<div className="section-content">
						<p className="section-text">
							لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
							صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
							متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
							است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
							متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای
							زیادی در شصت و سه درصد گذشته حال و آینده شناخت
							فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها
							شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
							طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد
							در این صورت می توان امید داشت که تمام و دشواری موجود
							در ارائه راهکارها
						</p>
					</div>
				</div>

				<div className="details-section project-gallery-section">
					<div className="section-title-wrap">
						<i className="section-icon isax isax-gallery"></i>
						<h3 className="section-title">تصاویر پروژه</h3>
					</div>
					<div className="section-content">
						<ProjectGallery />
					</div>
				</div>

				<div className="details-section">
					<div className="section-title-wrap">
						<i className="section-icon isax isax-tick-square"></i>
						<h3 className="section-title">ویژگی های اصلی</h3>
					</div>
					<div className="section-content">
						<ul className="project-properties">
							<li className="project-property">
								قابلیت مورد نظر در اینجا نوشته میشود
							</li>
							<li className="project-property">
								قابلیت مورد نظر در اینجا نوشته میشود
							</li>
							<li className="project-property">
								قابلیت مورد نظر در اینجا نوشته میشود
							</li>
							<li className="project-property">
								قابلیت مورد نظر در اینجا نوشته میشود
							</li>
						</ul>
					</div>
				</div>

				<div className="details-section project-techs-section">
					<div className="section-title-wrap">
						<i className="section-icon isax isax-code"></i>
						<h3 className="section-title">
							تکنولوژی های استفاده شده
						</h3>
					</div>
					<div className="section-content">
						<div className="desc">
							ممکن است در این پروژه از تکنولوژی های دیگری نیز
							استفاده شده باشد.
						</div>

						<ul className="project-techs">
							<li className="project-tech">
								<img
									src={require("../../../assets/pics/techs/html.png")}
									alt="html"
								/>
							</li>
							<li className="project-tech">
								<img
									src={require("../../../assets/pics/techs/css.png")}
									alt="css"
								/>
							</li>
							<li className="project-tech">
								<img
									src={require("../../../assets/pics/techs/js.png")}
									alt="js"
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="details-btns">
				<BtnPrimary className="details-btn">
					مشاهده سایت زنده
				</BtnPrimary>
			</div>
		</div>
	);
}
