import { CtaCard, BtnWithIcon } from "../../components";
import "../../styles/pages/shared/_join-us-section.scss";

export default function JoinUsSection() {
	return (
		<div className="join-us-section">
			<div className="join-us-content content-wrapper-lg">
				<CtaCard className="join-us-cta-card">
					<div className="cta-card-content-wrap">
						<div className="cta-card-content">
							<h2 className="cta-card-title">
								عضویت در تیم رکسومین
							</h2>
							<p className="cta-card-desc">
								جهت ارائه درخواست عضویت در رکسومین میتونید با ما
								در تلگرام در تماس باشید رکسومین همواره از همکاری
								با دوستان با استعداد استقبال میکند
							</p>
							<div className="cta-card-btns">
								<BtnWithIcon
									className="btn-contact-us"
									icon="isax isax-call"
								>
									تماس با ما
								</BtnWithIcon>
							</div>
						</div>

						<img
							className="cta-card-img"
							src={require("../../assets/pics/graphic-5.png")}
							alt="join-us-cta-card-img"
							width="658"
							height="320"
						/>
					</div>
				</CtaCard>
			</div>
		</div>
	);
}
