import { ProjectAcceptanceChartCard } from "../../components";
import "../../styles/pages/about/_project-acceptance-chart.scss";

export default function ProjectAcceptanceChart() {
	const benefits = [
		{
			img: "pachart1.svg",
			title: "۱. تعریف و بررسی نیازمندی‌ها:",
			description:
				"در اولین مرحله، با مشتری صحبت کرده و نیازمندی‌های پروژه را به دقت تعیین میکنیم. این شامل قابلیت‌ها، طراحی، عملکرد و هدف‌های پروژه است. ما اطلاعاتی را که ارائه کرده‌اید به دقت بررسی می‌کنیم تا پروژه و اهداف آن را کاملاً درک کنیم.",
		},
		{
			img: "pachart2.svg",
			title: "۲. تحلیل:",
			description:
				"پس از تعریف نیازمندی‌ها، ما نیازهای کلی پروژه را بررسی می‌کنیم و تحلیلی عمیق‌تر انجام می‌دهیم. این مرحله شامل تعیین وظایف و مسئولیت‌ها، برنامه زمانی و ساختار پروژه ماست.",
		},
		{
			img: "pachart3.svg",
			title: "۳. طراحی:",
			description:
				"در این مرحله، طرح کلی سایت را طراحی می‌کنیم. شامل طراحی رابط کاربری (UI)، نمایش داده‌ها و ساختار صفحات است.",
		},
		{
			img: "pachart4.svg",
			title: "۴. توسعه:",
			description:
				"در این مرحله، طرح کلی سایت را طراحی می‌کنیم. شامل طراحی رابط در این مرحله، متخصصان ما طراحی انجام شده در مرحله قبل را به کمک دانش برنامه نویسی به یک برنامه کاربردی تبدیل میکنند.",
		},
		{
			img: "pachart5.svg",
			title: "۵. تست و ارزیابی:",
			description:
				"پس از توسعه، پروژه را تست میکنیم تا از عملکرد صحیح آن اطمینان حاصل شود. تست‌ها می‌توانند شامل تست واحد (Unit test)، تست عملکرد (Performance testing)، تست نفوذ (penetration test) و تست کاربردی باشند.",
		},
		{
			img: "pachart6.svg",
			title: "۶. راه‌اندازی و استقرار:",
			description:
				"در مرحله بعد، پروژه را در سرور یا محیط زنده قرار داده و آماده بهره‌برداری میکنیم.",
		},
		{
			img: "pachart7.svg",
			title: "۷. پشتیبانی و نگهداری",
			description:
				"پس از راه‌اندازی، در صورت نیاز مشتری سایت را پشتیبانی کرده و به نگهداری آن میپردازیم. این شامل رفع اشکالات، به‌روزرسانی امنیتی و اضافه کردن قابلیت‌های جدید است.",
		},
	];

	return (
		<div className="project-acceptance-chart">
			<div className="project-acceptance-header-wrap content-wrapper-lg">
				<div className="project-acceptance-header">
					<h2 className="project-acceptance-title">
						<span className="bold-text">فرایند قبول</span> و انجام
						پروژه ها
					</h2>
				</div>
			</div>

			<div className="project-acceptance-content content-wrapper-lg no-transition">
				<div className="pa-chart-wrapper">
					<ul className="pa-chart">
						{benefits.map((benefit, index) => (
							<li className="pa-chart-item" key={index}>
								<ProjectAcceptanceChartCard
									img={benefit.img}
									title={benefit.title}
									description={benefit.description}
									direction={index % 2 === 0 ? "rtl" : "ltr"}
								/>
                                
                                {/* chart arrow */}
                                {index !== benefits.length - 1 && (
                                    <img src={require("../../assets/pics/pa-chart-arrow.svg").default} alt="chart arrow" className={"pa-chart-arrow " + (index % 2 === 0 ? "rtl" : "ltr")} />
                                )}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}
