import { BtnPrimary } from "../components";

import { Decoration1, Decoration2 } from "../components";

import "../styles/pages/page-404.scss";

export default function Page404() {
	return (
		<>
			{/* Decoration */}
			<div className="page-404-dec-1">
				<Decoration1 className="dec" />
			</div>

			<div className="page-404">
				<div className="page-404-content content-wrapper-lg">
					<div className="page-404-img">
						<img
							src={require("../assets/pics/page404.svg").default}
							alt="page not found"
						/>
					</div>

					<div className="page-404-inner-content">
						<h1 className="page-404-title">خطای 404</h1>
						<h1 className="page-404-desc">
							متاسفانه صفحه مورد نظر شما پیدا نشد!
						</h1>

						<div className="page-404-btn mt-5">
							<BtnPrimary>برگشت به صفحه اصلی</BtnPrimary>
						</div>
					</div>
				</div>
			</div>

			{/* Decoration */}
			<div className="page-404-dec-2">
				<Decoration2 className="dec" />
			</div>
		</>
	);
}
