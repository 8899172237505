import { useState } from "react";
import { InputPrimary, InputTextarea, BtnWithIcon } from "../../components";

import "../../styles/pages/shared/_req-counseling-form.scss";

export default function ReqCounselingForm({ ...props }) {
	const [loading, setLoading] = useState(false);

	// render the component
	return (
		<div
			{...props}
			className={
				"rx-req-counseling-form-wrapper" +
				(props.className ? " " + props.className : "")
			}
		>
			<h3 className="req-counseling-title">
				برای درخواست مشاوره، لطفا اطلاعات زیر را تکمیل کنید. کارشناسان ما در اسرع وقت با شما تماس خواهند گرفت.
			</h3>

			<form
				className="req-counseling-form"
				action=""
				onSubmit={(e) => e.preventDefault()}
			>
				<div className="row">
					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-user"}
								type="text"
								placeholder="نام و نام خانوادگی"
								required
							/>
						</div>
					</div>

					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-buildings"}
								type="text"
								placeholder="نام شرکت (اختیاری)"
							/>
						</div>
					</div>

					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-sms"}
								type="email"
								placeholder="ایمیل"
								required
							/>
						</div>
					</div>

					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-mobile"}
								type="tel"
								placeholder="شماره تماس"
								required
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="form-input">
							<InputTextarea
								type="password"
								placeholder="توضیحاتی کوتاه"
								required
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="form-btn">
							<BtnWithIcon
								className="submit-btn"
								icon="isax isax-arrow-left"
								loading={loading}
								onClick={() => setLoading(!loading)}
							>
								ثبت درخواست
							</BtnWithIcon>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
