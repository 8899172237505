import "../../styles/pages/shared/_title-hero.scss";

export default function TitleHero({ children , ...props }) {
	return (
		<div className={`title-hero` + (props.className ? ` ${props.className}` : "")}>
			<div className="title-hero-content content-wrapper-lg">
				<div className="hero-img">
					<img
						className="img-1"
						src={require("../../assets/pics/graphic-1.png")}
						alt="hero"
						width="523"
						height="500"
					/>
					<img
						className="img-2"
						src={require("../../assets/pics/graphic-2.png")}
						alt="hero"
						width="307"
						height="345"
					/>
				</div>

				<div className="hero-inner-content">{children}</div>
			</div>
		</div>
	);
}
