import { useState } from "react";
import { InputPrimary, BtnPrimary, BtnWithIcon } from "../../components";

import "../../styles/pages/shared/_quick-contact.scss";

export default function QuickContact({ ...props }) {
	const [loading, setLoading] = useState(false);
	
	const toggleLoading = () => {
		setLoading(!loading);
	};

	// pass component className
	let className = "rx-quick-contact";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<h3 className="quick-contact-title">
				همین حالا شمارتو ثبت کن کارشناس های ما با شما تماس خواهند گرفت
			</h3>
			<form
				className="quick-contact-form"
				action=""
				onSubmit={(e) => e.preventDefault()}
			>
				<div className="form-input">
					<InputPrimary
						icon={"isax isax-user"}
						type="text"
						placeholder="نام و نام خانوادگی"
					/>
				</div>
				<div className="form-input">
					<InputPrimary
						icon={"isax isax-call"}
						type="text"
						placeholder="شماره تماس"
					/>
				</div>
				<div className="form-btn">
					<BtnPrimary
						className="submit-btn-desktop"
						type="submit"
						iconOnly="isax isax-arrow-left"
						loading={loading}
						onClick={toggleLoading}
					/>
					<BtnWithIcon
						className="submit-btn-phone"
						icon="isax isax-arrow-left"
						loading={loading}
						onClick={toggleLoading}
					>
						ثبت درخواست
					</BtnWithIcon>
				</div>
			</form>
		</div>
	);
}
