import HeroSection1 from "./HeroSection1";
import HeroSection2 from "./HeroSection2";
import HeroSection3 from "./HeroSection3";
import OurBenefitsSection from "../shared/OurBenefitsSection";
import ProjectAcceptanceChart from "./ProjectAcceptanceChart";
import ReqCounselingSection from "../shared/ReqCounselingSection";
import GallerySection from "./GallerySection";
import JoinUsSection from "../shared/JoinUsSection";

import {
	Decoration1,
	Decoration2,
	Decoration3,
	Decoration4,
	Decoration5,
} from "../../components";

import "../../styles/pages/about/_decorations.scss";

export default function About() {
	return (
		<>
			{/* Decoration */}
			<div className="about-dec-1">
				<Decoration2 className="dec" />
			</div>

			<HeroSection1 />

			{/* Decoration */}
			<div className="about-dec-2">
				<Decoration1 className="dec" />
			</div>

			<HeroSection2 />
			<HeroSection3 />

			{/* Decoration */}
			<div className="about-dec-3">
				<Decoration4 className="dec" />
			</div>

			<OurBenefitsSection />

			{/* Decoration */}
			<div className="about-dec-4">
				<Decoration3 className="dec" />
			</div>

			<ProjectAcceptanceChart />

			{/* Decoration */}
			<div className="about-dec-5">
				<Decoration5 className="dec" />
			</div>

			<ReqCounselingSection />

			{/* Decoration */}
			<div className="about-dec-6">
				<Decoration1 className="dec" />
			</div>

			<GallerySection />
			<JoinUsSection />
		</>
	);
}
