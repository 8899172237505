import { useState } from "react";
import { InputPrimary, InputTextarea, BtnWithIcon } from "../../components";

import "../../styles/pages/shared/_contact-card.scss";

export default function ContactCard({ ...props }) {
	const [loading, setLoading] = useState(false);

	// render the component
	return (
		<div
			{...props}
			className={
				"rx-contact-card" +
				(props.className ? " " + props.className : "")
			}
		>
			<h3 className="contact-card-title">
				برای ما پیام بفرستید! در اسرع وقت پاسخگوی شما هستیم.
			</h3>

			<form
				className="contact-card-form"
				action=""
				onSubmit={(e) => e.preventDefault()}
			>
				<div className="row">
					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-user"}
								type="text"
								placeholder="نام و نام خانوادگی"
								required
							/>
						</div>
					</div>

					<div className="col-12 col-lg-6">
						<div className="form-input">
							<InputPrimary
								icon={"isax isax-sms"}
								type="email"
								placeholder="ایمیل"
								required
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="form-input">
							<InputTextarea
								type="text"
								placeholder="متن پیام"
								required
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="form-btn">
							<BtnWithIcon
								className="submit-btn"
								icon="isax isax-arrow-left"
								loading={loading}
								onClick={() => {
									setLoading(!loading);
								}}
							>
								ارسال پیام
							</BtnWithIcon>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
