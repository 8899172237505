import { BtnWithIcon } from "../../components";
import "../../styles/pages/home/_about-section.scss";

export default function AboutSection() {
	return (
		<div className="about-section">
			<div className="about-content-wrap content-wrapper-lg">
				<div className="about-title-mobile">
					<span className="bold-text">ما که</span> هستیم؟
				</div>

				<div className="about-pic">
					<img
						src={require("../../assets/pics/graphic-3.png")}
						alt="about"
						width="384"
						height="466"
					/>
				</div>

				<div className="about-content">
					<h2 className="about-title">
						<span className="bold-text">ما که</span> هستیم؟
					</h2>

					<p className="about-desc">
						شرکت برتر توسعه نرم‌افزار که متعهد است تا با ارائه
						راهکارهای نوآورانه، به کسب‌وکارها کمک کند تا رشد و
						موفقیتی بی‌سابقه را تجربه کنند. از زمان تأسیس در سال
						1402، ما در حال توانمندسازی سازمان‌ها در تمامی صنایع
						هستیم تا از تکنولوژی بهره‌برده و به رشد بیاندیشند.
						<br />
						با تیمی از حرفه‌ایان با تجربه و علاقه به نوآوری، ما به
						عبور از انتظارات مشتریانمان با ارائه محصولات و خدمات
						نرم‌افزاری استثنایی تلاش می‌کنیم. ما در زمینه توسعه وب،
						توسعه برنامه‌های موبایل، راهکارهای نرم‌افزاری سفارشی
						تخصص داریم و با بهره‌گیری از آخرین فناوری‌ها، تجربه
						کاربری بی‌درز و راهکارهای قابل مقیاس ایجاد می‌کنیم.
					</p>

					<div className="about-btns">
						<BtnWithIcon
							className="about-us-btn"
							icon="isax isax-arrow-left"
						>
							با ما بیشتر آشنا شوید
						</BtnWithIcon>
					</div>
				</div>
			</div>
		</div>
	);
}
