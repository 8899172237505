// import styles
import "../styles/components/_cta-card.scss";

export default function CtaCard({ children, ...props }) {
	// pass component className
	let className = "rx-cta-card";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			{children}
		</div>
	);
}
