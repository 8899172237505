import "../../styles/pages/about/_hero-section-2.scss";

export default function HeroSection1() {
	return (
		<div className="hero-section-2">
			<div className="hero-content-wrap content-wrapper-lg">
				<div className="hero-pic">
					<img
						src={require("../../assets/pics/graphic-7.png")}
						alt="hero"
						width="425"
						height="487"
					/>
				</div>

				<div className="hero-content">
					<p className="hero-text">
						با تیمی از حرفه‌ایان با تجربه و علاقه به نوآوری، ما به
						عبور از انتظارات مشتریانمان با ارائه محصولات و خدمات
						نرم‌افزاری استثنایی تلاش می‌کنیم. ما در زمینه [خدمات
						اصلی شما مانند توسعه وب، توسعه برنامه‌های موبایل،
						راهکارهای نرم‌افزاری سفارشی و غیره] تخصص داریم و با
						بهره‌گیری از آخرین فناوری‌ها، تجربه کاربری بی‌درز و
						راهکارهای قابل مقیاس ایجاد می‌کنیم.
						<br />
						<br />
						آنچه ما را از دیگران متمایز می‌کند، نه تنها تخصص فنی ما
						است، بلکه تعهد ما برای برقراری روابط قوی با مشتریانمان
						است. ما وقت می‌گذاریم تا اهداف، چالش‌ها و آرزوهای آنها
						را درک کنیم تا بتوانیم راهکارهایی سفارشی طراحی کنیم که
						نتیجه مطلوب را به همراه داشته باشند. در Rexomin، شفافیت،
						ارتباط باز و همکاری را مهم می‌دانیم و طی فرآیند توسعه با
						مشتریانمان به‌طور نزدیک همکاری می‌کنیم تا بتوانیم به
						تحقق ایده‌هایشان برسیم.
					</p>
				</div>
			</div>
		</div>
	);
}
