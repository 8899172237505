import React from "react";
import { ServiceCardMobile } from "../../components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { useRef } from "react";

export default function OurServicesSlider({ services }) {
	const dotsCount = 4;

	const sliderRef = useRef();
	const sliderOptions = {
		direction: "rtl",
		type: "loop",
		gap: "15px",
		focus: "center",
		perMove: 1,
		autoWidth: true,
		arrows: false,
		pagination: false,
	};

	function renderActiveDot(activeIndex, totalIndex) {
		// calculate the active dot
		let activeDot = Math.round(
			((activeIndex + 1) / (totalIndex + 1)) * dotsCount
		);

		// prevent invalid active dot
		if(activeDot <= 0) {
			activeDot = 1;
		};

		//get dots and give active class to the active dot
		const dots = document.querySelectorAll(
			".our-services-slider-wrapper .slider-dot"
		);
		dots.forEach((dot, index) => {
			if (index === activeDot - 1) {
				dot.classList.add("active");
			} else {
				dot.classList.remove("active");
			}
		});
	}

	return (
		<div className="our-services-slider-wrapper">
			<Splide
				options={sliderOptions}
				ref={sliderRef}
				onMounted={(slider) => {
					const totalIndex = slider.Components.Controller.getEnd();
					const activeIndex = slider.Components.Controller.getIndex();
					renderActiveDot(activeIndex, totalIndex);
				}}
				onMove={(slider) => {
					const totalIndex = slider.Components.Controller.getEnd();
					const activeIndex = slider.Components.Controller.getIndex();
					renderActiveDot(activeIndex, totalIndex);
				}}
			>
				{services.map((service, index) => (
					<SplideSlide key={index} style={{width: 300}}>
						<div className="our-services-item">
							<ServiceCardMobile
								title={service.title}
								icon={service.icon}
								description={service.description}
								iconColor={service.iconColor}
								iconBgColor={service.iconBgColor}
							/>
						</div>
					</SplideSlide>
				))}
			</Splide>

			<div className="slider-dots">
				{[...Array(dotsCount)].map((_, index) => (
					<div className={`slider-dot`} key={index}></div>
				))}
			</div>
		</div>
	);
}
