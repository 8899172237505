import ContactCard from "../shared/ContactCard";

import { Decoration1, Decoration2 } from "../../components";

import "../../styles/pages/contactUs/_index.scss";

export default function ContactUs() {
	return (
		<>
			{/* Decoration */}
			<div className="contact-us-dec-1">
				<Decoration2 className="dec" />
			</div>

			<div className="contact-us">
				<div className="contact-us-content content-wrapper-lg">
					<div className="contact-us-inner-content">
						<div className="contact-us-title-wrap">
							<div className="contact-us-top-title">
								تماس با ما
							</div>
							<h2 className="contact-us-title">
								همیشه در دسترس و پاسخگوی شما هستیم
							</h2>
						</div>

						<div className="contact-info">
							<ul className="contact-info-list">
								<li>
									<div className="contact-info-icon">
										<i className="isax isax-sms"></i>
									</div>
									<div className="contact-info-text">
										<p>support@rexomin.com</p>
									</div>
								</li>
								<li>
									<div className="contact-info-icon">
										<i className="isax isax-call"></i>
									</div>
									<div className="contact-info-text">
										<p>+989339807135</p>
									</div>
								</li>
								<li>
									<div className="contact-info-icon">
										<i className="isax isax-call"></i>
									</div>
									<div className="contact-info-text">
										<p>+989339807135</p>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div className="contact-us-card">
						<ContactCard />
					</div>
				</div>
			</div>

			{/* Decoration */}
			<div className="contact-us-dec-2">
				<Decoration1 className="dec" />
			</div>
		</>
	);
}
