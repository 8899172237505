import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from "./pages/App";

//rtl mui
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import "./styles/main.scss";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
	direction: 'rtl',
	typography: {
		fontFamily: [
			'Yekan Bakh',
			'sans-serif'
		].join(','),
	},
	palette: {
		primary: {
			main: '#0EA5E9',
		},
		secondary: {
			main: '#FF7A00',
		},
	},
});

//rtl mui
const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<CacheProvider value={cacheRtl}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</CacheProvider>
	</React.StrictMode>
);