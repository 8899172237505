// import styles
import { useEffect } from "react";
import { getRandomNumber } from "../../utils/randomUtils";
import "../../styles/components/decorations/_decoration2.scss";

export default function Decoration2({ size = 166, ...props }) {
	// pass component className
	let className = "rx-decoration-2";
	if (props.className) {
		className += ` ${props.className}`;
	}

	let aspectRatio = 166 / 167;
	let width = size;
	let height = size / aspectRatio;

	//random colors
	useEffect(() => {
		let colors = [
			"--decoration-color-1",
			"--decoration-color-2",
			"--decoration-color-3",
		];
		let interval = setInterval(() => {
			if (Math.random() < 0.7) {
				//shuffle colors
				colors = colors.sort(() => Math.random() - 0.5);
				document.querySelector(
					`.rx-decoration-2 path:nth-child(1)`
				).style.stroke = `var(${colors[0]})`;
				document.querySelector(
					`.rx-decoration-2 path:nth-child(2)`
				).style.stroke = `var(${colors[1]})`;
				document.querySelector(
					`.rx-decoration-2 path:nth-child(3)`
				).style.stroke = `var(${colors[2]})`;

			}
		}, getRandomNumber(5000, 7000));

		return () => {
			clearInterval(interval);
		};
	}, []);

	// render the component
	return (
		<div {...props} className={className}>
			<svg
				width={width}
				height={height}
				viewBox="0 0 166 167"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M67.249 34.9445C43.4365 42.5212 25.5905 68.5147 36.8975 90.3477C55.5903 126.478 101.87 114.458 101.957 81.4036C102.017 58.4921 69.9739 52.3066 64.9783 75.9413"
					stroke="#E1E8EB"
					strokeWidth="16"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M127.43 72.6247C126.233 49.0894 102.396 34.2573 92.8259 35.4047"
					stroke="#FF7C04"
					strokeWidth="16"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M105.951 129.216C105.951 129.216 120.761 117.427 126.66 99.2147"
					stroke="#0EA5E9"
					strokeWidth="16"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M78.2729 88.095C78.2729 88.095 87.8949 125.965 73.9166 140.281C59.9383 154.597 65.017 100.381 56.9747 96.3825C48.9324 92.3844 44.008 120.633 32.1347 110.279C20.2614 99.9257 54.244 82.3836 46.8588 73.1788C39.4736 63.974 24.2649 66.4964 24.2649 66.4964"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
