// import styles
import "../../styles/components/decorations/_decoration4.scss";

export default function Decoration4({ size = 164, ...props }) {
	// pass component className
	let className = "rx-decoration-4";
	if (props.className) {
		className += ` ${props.className}`;
	}

	let aspectRatio = 164 / 205;
	let width = size;
	let height = size / aspectRatio;

	// render the component
	return (
		<div {...props} className={className}>
			<svg
				width={width}
				height={height}
				viewBox="0 0 164 205"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M96.0092 160.663C88.4899 162.684 80.645 163.199 72.9229 162.18C65.2007 161.16 57.7526 158.625 51.0042 154.72C44.2559 150.815 38.3396 145.616 33.5934 139.421C28.8472 133.225 25.3643 126.155 23.3435 118.613C14.8496 86.9135 34.083 51.7996 65.7396 43.3173C97.3962 34.8349 132.672 58.9508 141.166 90.6507C149.66 122.351 127.622 152.193 96.0092 160.663Z"
					fill="#E1E8EB"
				/>
				<path
					d="M101.194 108.671C101.194 108.671 147.184 136.692 146.28 143.068C145.376 149.443 121.219 186.614 115.66 185.484C110.101 184.354 68.7099 160.418 68.6332 156.862C68.5564 153.306 96.6832 108.123 101.194 108.671Z"
					fill="#FF7C04"
				/>
				<path
					d="M24.5198 57.9075C27.3656 68.5283 38.2588 74.8375 48.8505 71.9995C59.4421 69.1615 65.7214 58.2509 62.8755 47.6301C60.0297 37.0093 49.1364 30.7001 38.5448 33.5381C27.9531 36.3761 21.6739 47.2867 24.5198 57.9075Z"
					stroke="#0EA5E9"
					strokeWidth="16"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M84.7964 57.2229C85.6987 56.6203 127.436 64.2757 127.607 116.464"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M61.5327 143.572C60.7563 143.918 29.5318 132.497 36.2876 92.1678"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
