import "../../styles/pages/about/_hero-section-3.scss";

export default function HeroSection1() {
	return (
		<div className="hero-section-3">
			<div className="hero-content-wrap content-wrapper-lg">
				<div className="hero-content">
					<p className="hero-text">
						در Rexomin، از دستاوردهای خود بهره‌برداری می‌کنیم. ما به
						شرف همکاری با مشتریانی از انواع و اقسام صنایع از
						استارتاپ‌ها تا شرکت‌های بزرگ برخوردار بوده‌ایم و نتایج
						استثنایی در پروژه‌هایی با پیچیدگی‌های مختلف به آنها
						ارائه داده‌ایم. تفانگری، دقت و عشق به کیفیت ما را در
						داخل صنعت مورد تقدیر قرار داده است.
						<br />
						<br />
						علاوه بر تخصص فنی، ما توسط مجموعه‌ای از ارزش‌هایی که کار
						ما را هدایت می‌کنند، مشتاقانه کار می‌کنیم. ما متعهد به
						ارائه راهکارهای بالاترین کیفیت هستیم، در صدد هستیم در
						پیشگاه فناوری‌های جدید بمانیم و خدمات بی‌نظیر به
						مشتریانمان را فراهم کنیم. فرهنگ شرکت ما خلاقیت، همکاری و
						یادگیری پیوسته را ترویج می‌دهد و ما را قادر می‌سازد تا
						حدود امکانات نرم‌افزاری را گسترش دهیم..
					</p>
				</div>

				<div className="hero-pic">
					<img
						src={require("../../assets/pics/graphic-8.png")}
						alt="hero"
						width="528"
						height="426"
					/>
				</div>
			</div>
		</div>
	);
}
