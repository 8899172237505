// import styles
import "../styles/components/_our-benefits-card.scss";

export default function OurBenefitsCard({
	icon,
	title,
	description,
	...props
}) {
	// pass component className
	let className = "rx-our-benefits-card-wrap";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="our-benefits-card">
				<div className="our-benefits-card-header">
					<div className="our-benefits-card-icon-wrap">
						<i className={icon}></i>
					</div>

					<h3 className="our-benefits-card-title">{title}</h3>
				</div>

				<div className="our-benefits-card-content">
					<p className="our-benefits-card-description">
						{description}
					</p>
				</div>
			</div>
		</div>
	);
}
