import { CtaCard, BtnPrimary } from "../../components";
import "../../styles/pages/shared/_req-counseling-section.scss";
import EventEmitter from "../../utils/EventEmitter";

export default function ReqCounselingSection() {
	return (
		<div className="req-counseling-section">
			<div className="req-counseling-content content-wrapper-lg">
				<CtaCard className="req-counseling-cta-card">
					<div className="cta-card-content-wrap">
						<div className="cta-card-content">
							<p className="cta-card-desc">
								برای ثبت سفارش و یا درخواست مشاوره لطفا روی دکمه
								زیر کلیک کنید
							</p>
							<div className="cta-card-btns">
								<BtnPrimary className="req-counseling-btn" onClick={() => EventEmitter.emit("openReqCounselingModal")}>
									<i
										className="isax isax-edit-2"
										style={{
											verticalAlign: "middle",
											marginLeft: "4px",
										}}
									></i>
									درخواست مشاوره
								</BtnPrimary>
							</div>
						</div>

						<img
							className="cta-card-img"
							src={require("../../assets/pics/graphic-9.png")}
							alt="req-counseling-cta-card-img"
							width="516"
							height="169"
						/>
					</div>
				</CtaCard>
			</div>
		</div>
	);
}
