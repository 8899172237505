// import styles
import { useEffect } from "react";
import "../styles/components/_accordion.scss";

export default function Accordion({ data, ...props }) {
	//handle accordion item toggle
	function toggleAccordion(e) {
		// get parent element
		const accordionItem = e.target.parentElement;
		const accordionContent = e.target.nextElementSibling;

		if (accordionItem.classList.contains("show")) {
			accordionContent.style.maxHeight = "0";
			accordionItem.classList.remove("show");
		} else {
			// Calculate the actual height of the content and set it as the max-height
			const contentHeight = accordionContent.scrollHeight + "px";
			accordionContent.style.maxHeight = contentHeight;
			accordionItem.classList.add("show");
		}
	}

	// Function to update accordion item content height on window resize
	function updateAccordionContentHeight() {
		const accordionContents =
			document.querySelectorAll(".accordion-content");
		accordionContents.forEach((accordionContent) => {
			if (accordionContent.parentElement.classList.contains("show")) {
				// Recalculate the actual height of the content and update the max-height
				const contentHeight = accordionContent.scrollHeight + "px";
				accordionContent.style.maxHeight = contentHeight;
			}
		});
	}

	// Add event listener for window resize on component mount
	useEffect(() => {
		window.addEventListener("resize", updateAccordionContentHeight);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateAccordionContentHeight);
		};
	}, []);

	// pass component className
	let className = "rx-accordion";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="accordion-items">
				{data.map((item, index) => (
					<div className="accordion-item" key={index}>
						<div
							className="accordion-btn"
							onClick={toggleAccordion}
						>
							{item.title}

							<div className="accordion-icon">
								<i className="isax isax-arrow-down-1"></i>
							</div>
						</div>
						<div className="accordion-content">{item.content}</div>
					</div>
				))}
			</div>
		</div>
	);
}
