import QuickContact from "../shared/QuickContact";
import "../../styles/pages/home/_hero-section.scss";

export default function HeroSection() {
	return (
		<div className="hero-section">
			<div className="hero-content-wrap content-wrapper-lg">
				<div className="hero-content">
					<h2 className="hero-slogan">همه چیز را به ما بسپارید!</h2>
					<h1 className="hero-title">
						شرکت نرم افزاری{" "}
						<span className="primary-text">رکسومین</span>
					</h1>
					<p className="hero-desc">
						با ما به دنیایی از افق‌های دیجیتالی بی‌پایان خوش‌آمدید،
						جایی که هر خط کدی که می‌نویسیم یک قدم به سمت ساخت
						آینده‌ای روشن از طریق راه‌حل‌های نرم‌افزاری سفارشی است!
					</p>

					<QuickContact className="hero-quick-contact" />
				</div>

				<div className="hero-img">
					<img
						className="img-1"
						src={require("../../assets/pics/graphic-1.png")}
						alt="hero"
						width="523"
						height="500"
					/>
					<img
						className="img-2"
						src={require("../../assets/pics/graphic-2.png")}
						alt="hero"
						width="307"
						height="345"
					/>
				</div>

				<div className="mobile-quick-contact">
					<QuickContact />
				</div>
			</div>
		</div>
	);
}
