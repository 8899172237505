import * as React from "react";
import { Backdrop, Fade } from "@mui/material";
import Modal from "@mui/material/Modal";
import EventEmitter from "../../../utils/EventEmitter";
import { useEffect } from "react";
import "../../../styles/pages/shared/modals/_modal-base.scss";

export default function ModalBase({ listener , title, children, ...props }) {
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		EventEmitter.addListener(listener, () => {
			handleOpen();
		});
	}, [listener]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
				sx={{ padding: "10px !important" }}
			>
				<Fade in={open}>
					<div className="modal-content-wrap">
						<div className="modal-content">
							<div className="modal-head">
								<h1 className="modal-title">{title}</h1>

								<button
									className="modal-close"
									onClick={handleClose}
								>
									<i className="isax isax-close-circle"></i>
								</button>
							</div>

							<div className="modal-body">
								{children}
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
}
