// import styles
import "../styles/components/_project-acceptance-chart-card.scss";

export default function ProjectAcceptanceChartCard({
	img,
	title,
	description,
	direction = "rtl",
	...props
}) {
	// pass component className
	let className = "rx-project-acceptance-chart-card";
    if (direction === "ltr") {
        className += " ltr";
    }
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="project-acceptance-chart-card-img">
				<img
                    className="project-acceptance-chart-card-img"
                    src={require(`../assets/pics/${img}`)}
                    alt="project acceptance chart card"
                />
			</div>

			<div className="project-acceptance-chart-card-content">
				<h3 className="project-acceptance-chart-card-title">
					{title}
				</h3>

				<p className="project-acceptance-chart-card-description">
					{description}
				</p>
			</div>
		</div>
	);
}
