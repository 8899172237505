import "../styles/components/_loader.scss";

export default function Loader({ size = 48, thickness = 5, color = "var(--primary-color)", ...props }) {
	//calculate width and height
	let style = {
        "--loader-size": size + "px",
        "--loader-thickness": thickness + "px",
        "--loader-color": color,
    };

	return (
		<div
			className={
				"rx-loader" + (props.className ? " " + props.className : "")
			}
            style={style}
		/>
	);
}
