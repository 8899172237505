import { Route, Routes } from "react-router-dom";
import Home from "./home";
import About from "./about";
import Portfolio from "./portfolio";
import ContactUs from "./contactUs";
import Page404 from "./Page404";
import Layout from "./layout";
import ReqCounsleingModal from "../pages/shared/modals/ReqCounselingModal";

export default function App() {
    return <>
        <Layout>
            {/* shared modals */}
            <ReqCounsleingModal />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact-us" element={<ContactUs />} />

                <Route path="*" element={<Page404 />} />
            </Routes>
        </Layout>
    </>
}
