import HeroSection from "./HeroSection";
import OurServciesSection from "./OurServicesSection";
import AboutSection from "./AboutSection";
import PortfolioSection from "./PortfolioSection";
import OurBenefitsSection from "../shared/OurBenefitsSection";
import FaqSection from "./FaqSection";
import ProjectAcceptanceSection from "./ProjectAcceptanceSection";
import JoinUsSection from "../shared/JoinUsSection";

import {
	Decoration1,
	Decoration2,
	Decoration3,
	Decoration4,
	Decoration5,
} from "../../components";

import "../../styles/pages/home/_decorations.scss";

export default function Home() {
	return (
		<>
			<HeroSection />

			{/* Decoration */}
			<div className="home-dec-1">
				<Decoration1 className="dec" />
			</div>

			<OurServciesSection />

			{/* Decoration */}
			<div className="home-dec-2">
				<Decoration2 className="dec" />
			</div>

			<AboutSection />

			{/* Decoration */}
			<div className="home-dec-3">
				<Decoration3 className="dec" />
			</div>

			<PortfolioSection />
			<OurBenefitsSection />

			{/* Decoration */}
			<div className="home-dec-4">
				<Decoration4 className="dec" />
			</div>

			<FaqSection />

			{/* Decoration */}
			<div className="home-dec-5">
				<Decoration5 className="dec" />
			</div>

			<ProjectAcceptanceSection />
			<JoinUsSection />
		</>
	);
}
