import TitleHero from "../shared/TitleHero";
import PortfolioGrid from "./PortfolioGrid";
import ReqCounselingSection from "../shared/ReqCounselingSection";

import "../../styles/pages/portfolio/_index.scss";

export default function Portfolio() {
	const projects = [
		{
			title: "سایت فروشگاهی",
			properties: [
				{
					icon: "isax isax-clock",
					title: "مدت زمان",
					value: "۲۰ روز",
				},
				{
					icon: "isax isax-money",
					title: "قیمت",
					value: "۲۰۰۰۰۰۰ تومان",
				},
			],
			primaryBtnText: "مشاهده سایت",
			outlineBtnText: "مشاهده سایت زنده",
		},
		{
			title: "سایت فروشگاهی",
			properties: [
				{
					icon: "isax isax-clock",
					title: "مدت زمان",
					value: "۲۰ روز",
				},
				{
					icon: "isax isax-money",
					title: "قیمت",
					value: "۲۰۰۰۰۰۰ تومان",
				},
			],
			primaryBtnText: "مشاهده سایت",
			outlineBtnText: "مشاهده سایت زنده",
		},
	];

	return (
		<>
			<TitleHero className="portfolio-hero-title">
				<h2 className="portfolio-title">
					<span className="bold-text">نمونه کار های</span> ما
				</h2>
			</TitleHero>

			<PortfolioGrid items={projects} />

			<ReqCounselingSection />
		</>
	);
}
