// import styles
import "../../styles/components/decorations/_decoration1.scss";

export default function Decoration1({ size = 156, ...props }) {
	// pass component className
	let className = "rx-decoration-1";
	if (props.className) {
		className += ` ${props.className}`;
	}

	let aspectRatio = 156 / 142;
	let width = size;
	let height = size / aspectRatio;

	// render the component
	return (
		<div {...props} className={className}>
			<svg
				className="decoration-svg"
				width={width}
				height={height}
				viewBox="0 -15 160 170"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M144.094 67.4748C144.094 75.9193 142.43 84.2811 139.197 92.0827C135.965 99.8842 131.226 106.973 125.253 112.943C119.28 118.913 112.189 123.649 104.385 126.879C96.5816 130.109 88.2177 131.771 79.7716 131.768C44.2485 131.768 12.9238 101.745 12.9238 66.1794C12.9238 30.6139 48.0205 0.410156 83.5437 0.410156C119.067 0.410156 144.094 31.942 144.094 67.4748Z"
					fill="#E1E8EB"
				/>
				<path
					d="M78.6071 106.451C99.7205 106.451 116.836 89.3382 116.836 68.2288C116.836 47.1194 99.7205 30.0068 78.6071 30.0068C57.4937 30.0068 40.3779 47.1194 40.3779 68.2288C40.3779 89.3382 57.4937 106.451 78.6071 106.451Z"
					fill="#FF7C04"
				/>
				<path
					d="M2.42712 52.3071C2.42712 52.3071 -8.97112 118.683 54.695 140.344"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M125.151 1.63965C126.644 1.63965 157.148 26.1207 154.147 66.048"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
