import ProjectDetails from "../projectDetails";
import ModalBase from "./ModalBase";

export default function ProjectDetailsModal() {
	return (
		<ModalBase listener="openProjectDetailsModal" title="جزئیات پروژه">
			<ProjectDetails />
		</ModalBase>
	);
}
