import GallerySlider from "./GallerySlider";
import "../../styles/pages/about/_gallery-section.scss";

export default function GallerySection() {
	return (
		<div className="gallery-section">
			<div className="gallery-section-header-wrap content-wrapper-lg">
				<div className="gallery-section-header">
					<h2 className="gallery-section-title">
						<span className="bold-text">گالری</span> عکس
					</h2>
				</div>
			</div>

			<div className="gallery-section-content content-wrapper-lg no-transition">
				<GallerySlider />
			</div>
		</div>
	);
}
