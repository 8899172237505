import { ServiceCard } from "../../components";
import OurServicesSlider from "./OurServicesSlider";
import "../../styles/pages/home/_our-services-section.scss";

export default function OurServciesSection() {
	const services = [
		{
			title: "طراحی وب سایت",
			icon: "isax isax-monitor",
			description: "طراحی وب سایت با استفاده از تکنولوژی‌های روز و با کیفیت بالا.",
			iconColor: "#29804C",
			iconBgColor: "#DFFFEC",
		},
		{
			title: "طراحی گرافیک و Ui/Ux",
			icon: "isax isax-pen-tool-2",
			description: "طراحی گرافیکی شامل ایجاد طرح‌های جذاب و رابط کاربری بهینه برای تجربه کاربری بهتر.",
			iconColor: "#143E64",
			iconBgColor: "#CFE8FF",
		},
		{
			title: "طراحی نرم افزار و اپلیکیشن",
			icon: "isax isax-monitor-mobbile",
			description: "طراحی نرم افزارها و اپلیکیشن‌های متناسب با نیازهای کاربران و با استفاده از آخرین تکنولوژی‌ها.",
			iconColor: "#453675",
			iconBgColor: "#ECE5FF",
		},
		{
			title: "پشتیبانی و نگهداری",
			icon: "isax isax-driver-2",
			description: "ارائه خدمات پشتیبانی و نگهداری برای حفظ عملکرد بهینه و کیفیت خدمات فناوری.",
			iconColor: "#C6803A",
			iconBgColor: "#FFEEDF",
		},
		{
			title: "طراحی بازی",
			icon: "isax isax-game",
			description: "طراحی بازی‌های جذاب و سرگرم‌کننده با استفاده از الهام‌بخشنده‌های مختلف.",
			iconColor: "#143E64",
			iconBgColor: "#CFE8FF",
		},
		{
			title: "دیجیتال مارکتینگ (SEO)",
			icon: "isax isax-search-status",
			description: "استراتژی‌های دیجیتال مارکتینگ برای بهبود قابلیت دید در موتورهای جستجو و جلب مخاطبان هدف.",
			iconColor: "#A52323",
			iconBgColor: "#F9DFDF",
		},
		{
			title: "بلاکچین و web3",
			icon: "isax isax-code",
			description: "بهره‌گیری از فناوری بلاکچین و وب 3.0 برای ایجاد اپلیکیشن‌ها و خدمات نوآورانه.",
			iconColor: "#C6803A",
			iconBgColor: "#FFEEDF",
		},
		{
			title: "تدوین و صدا گذاری",
			icon: "isax isax-video-play",
			description: "تدوین و تولید محتواهای تصویری و صوتی با کیفیت برای استفاده در ویدیوها و محتواهای مختلف.",
			iconColor: "#29804C",
			iconBgColor: "#DFFFEC",
		},
	];

	return (
		<div className="our-services-section">
			<div className="our-services-content-wrap content-wrapper-lg">
				<h2 className="our-services-title">
					<span className="bold-text">خدمات</span> ما
				</h2>

				<div className="our-services-content-desktop">
					<div className="our-services-items row">
						{services.map((service, index) => (
							<div
								className="our-services-item col-12 col-md-6"
								key={index}
							>
								<ServiceCard
									title={service.title}
									icon={service.icon}
									description={service.description}
									iconColor={service.iconColor}
									iconBgColor={service.iconBgColor}
								/>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="our-services-content-mobile">
				<OurServicesSlider services={services} />
			</div>
		</div>
	);
}
