import PortfolioSlider from "./PortfolioSlider";
import "../../styles/pages/home/_portfolio-section.scss";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import { Box } from "@mui/material";

export default function PortfolioSection() {
	const projects = [
		{
			title: "سایت فروشگاهی",
			properties: [
				{
					icon: "isax isax-clock",
					title: "مدت زمان",
					value: "۲۰ روز",
				},
				{
					icon: "isax isax-money",
					title: "قیمت",
					value: "۲۰۰۰۰۰۰ تومان",
				},
			],
			primaryBtnText: "مشاهده سایت",
			outlineBtnText: "مشاهده سایت زنده",
		},
		{
			title: "سایت فروشگاهی",
			properties: [
				{
					icon: "isax isax-clock",
					title: "مدت زمان",
					value: "۲۰ روز",
				},
				{
					icon: "isax isax-money",
					title: "قیمت",
					value: "۲۰۰۰۰۰۰ تومان",
				},
			],
			primaryBtnText: "مشاهده سایت",
			outlineBtnText: "مشاهده سایت زنده",
		},
	];

	const [tabValue, setTabValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<div className="portfolio-section">
			<div className="portfolio-header-wrap content-wrapper-lg">
				<div className="portfolio-header">
					<div className="portfolio-header-start">
						<h2 className="portfolio-title">
							<span className="bold-text">نمونه کار های</span> ما
						</h2>
					</div>

					<div className="portfolio-header-end">
						<Box
							sx={{
								borderBottom: 1,
								borderColor: "divider",
								width: "100%",
								maxWidth: "max-content",
							}}
						>
							<Tabs
								value={tabValue}
								onChange={handleChange}
								textColor="secondary"
								indicatorColor="secondary"
								variant="scrollable"
								scrollButtons="auto"
							>
								<Tab label="سایت" />
								<Tab label="اپلیکیشن" />
								<Tab label="برندینگ" />
								<Tab label="هویت بصری" />
							</Tabs>
						</Box>
					</div>
				</div>
			</div>

			<div className="portfolio-content content-wrapper-lg no-transition">
				<PortfolioSlider items={projects} />
			</div>
		</div>
	);
}
