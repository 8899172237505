import { Accordion } from "../../components/";
import "../../styles/pages/home/_faq-section.scss";

export default function FaqSection() {
	return (
		<div className="faq-section">
			<div className="faq-content-wrap content-wrapper-lg">
				<div className="faq-title-mobile">
					<span className="bold-text">سوالات</span> متداول
				</div>

				<div className="faq-content">
					<h2 className="faq-title">
						<span className="bold-text">سوالات</span> متداول
					</h2>

					<div className="faq-accordion">
						<Accordion
							data={[
								{
									title: "شرکت شما به چه زمینه‌هایی در زمینه نرم‌افزار فعالیت می‌کند؟",
									content:
										"شرکت ما در زمینه توسعه نرم‌افزارهای تجاری، وب‌اپلیکیشن‌ها، اپلیکیشن‌های موبایل و راه‌حل‌های سفارشی برای مشتریان از صنایع مختلف فعالیت دارد.",
								},
								{
									title: "چه تجربه‌ای در ارائه خدمات نرم‌افزاری دارید؟",
									content:
										"با بیش از 10 سال تجربه در عرصه توسعه نرم‌افزار و همکاری با مشتریان بزرگ و کوچک، ما توانسته‌ایم راه‌حل‌های خلاقانه و کارآمد را ارائه دهیم و به مشکلات مشتریان پاسخ دهیم.",
								},
								{
									title: "چه برنامه‌های پشتیبانی و پس از فروش ارائه می‌دهید؟",
									content:
										"ما بعد از اتمام پروژه، پشتیبانی مستمر و به‌روزرسانی‌های نرم‌افزاری را فراهم می‌کنیم تا اطمینان حاصل شود که محصول بهینه‌ای را به مشتری ارائه داده‌ایم.",
								},
								{
									title: "چگونه با چالش‌های فنی و مشکلات در طول پروژه برخورد می‌کنید؟",
									content:
										"ما با تشکیل یک تیم فنی مجرب و استفاده از روش‌های توسعه چابک، به چالش‌ها پاسخ می‌دهیم. همچنین، ارتباط مداوم با مشتریان و انعطاف در تغییر مسیر پروژه به حل بهتر مشکلات کمک می‌کند.",
								},
								{
									title: "چگونه تضمین می‌کنید که پروژه به موقع و با کیفیت تحویل داده می‌شود؟",
									content:
										"ما از برنامه‌ریزی دقیق و کنترل مستمر استفاده می‌کنیم تا پروژه‌ها را به موقع و با کیفیت انجام دهیم. همچنین، تخصیص منابع مناسب و مدیریت صحیح زمان کمک می‌کند تا تاخیرهای غیرضروری جلوگیری شود.",
								},
							]}
						/>
					</div>
				</div>

				<div className="faq-pic">
					<img
						src={require("../../assets/pics/graphic-4.png")}
						alt="faq"
						width="415"
						height="404"
					/>
				</div>
			</div>
		</div>
	);
}
