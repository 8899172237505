// import styles
import "../../styles/components/decorations/_decoration5.scss";

export default function Decoration5({ size = 155, ...props }) {
	// pass component className
	let className = "rx-decoration-5";
	if (props.className) {
		className += ` ${props.className}`;
	}

	let aspectRatio = 155 / 168;
	let width = size;
	let height = size / aspectRatio;

	// render the component
	return (
		<div {...props} className={className}>
			<svg
				width={width}
				height={height}
				viewBox="-2 0 175 178"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M154.846 78.1948C154.846 98.0912 146.919 117.173 132.807 131.242C118.696 145.31 99.5571 153.214 79.6007 153.214C38.0448 153.214 1.40039 118.191 1.40039 76.7028C1.40039 35.2145 42.4574 -0.019043 84.0133 -0.019043C125.569 -0.019043 154.846 36.7256 154.846 78.1948Z"
					fill="#E1E8EB"
				/>
				<path
					d="M72.6047 137.46C76.4786 118.184 63.9453 99.4262 44.6106 95.5638C25.276 91.7015 6.46167 104.197 2.58769 123.474C-1.28628 142.75 11.2471 161.508 30.5817 165.37C49.9164 169.233 68.7307 156.737 72.6047 137.46Z"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M108.686 35.4437C91.86 20.3901 74.3628 25.2677 67.9356 27.2187C67.7072 27.2845 67.4997 27.4079 67.3331 27.577C67.1666 27.7462 67.0466 27.9553 66.9849 28.1842C66.9232 28.4131 66.9217 28.654 66.9807 28.8837C67.0397 29.1133 67.1572 29.3238 67.3217 29.4949L124.878 91.7561C127.612 81.7915 127.548 71.2696 124.692 61.339C121.837 51.4084 116.3 42.4507 108.686 35.4437Z"
					fill="#FF7C04"
				/>
				<path
					d="M86.4121 71.6914C87.4289 73.2025 153.082 145.162 153.082 145.162"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
