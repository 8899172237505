import * as colorUtils from "../utils/colorUtils";

// import styles
import "../styles/components/_btns.scss";
import Loader from "./Loader";

export default function BtnPrimary({
	color,
	children,
	href,
	outline,
	neutral,
	iconOnly,
	onClick,
	text,
	loading = false,
	...props
}) {
	const TagName = href ? "a" : "button";

	if (color) {
		var hoverColor = colorUtils.getActionColor(color);
		var style = {
			"--btn-color": color,
			"--btn-color-hover": hoverColor,
		};
	}

	// pass component className
	let className = "rx-btn-primary";
	if (outline) {
		className += ` btn-outline`;
	}
	if (neutral) {
		className += ` btn-neutral`;
	}
	if (iconOnly) {
		className += ` btn-icon-only`;
	}
	if (text) {
		className += ` btn-text`;
	}
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<TagName
			{...props}
			className={className}
			style={style}
			onClick={onClick}
		>
			{loading && (
				<div className="btn-loader">
					<Loader size={20} thickness={2} color="#fff" />
				</div>
			)}

			<span className={"btn-content" + (loading ? " btn-loading" : "")}>
				{iconOnly && <i className={iconOnly}></i>}
				{children}
			</span>
		</TagName>
	);
}
