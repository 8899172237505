// import styles
import "../styles/components/_service-card.scss";

export default function ServiceCard({ icon, title, description, iconColor, iconBgColor, ...props }) {
	// pass component className
	let className = "rx-service-card";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// set icon color
	if (iconColor) {
		props.style = {
			...props.style,
			"--icon-color": iconColor,
		};
	}

	// set icon background color
	if (iconBgColor) {
		props.style = {
			...props.style,
			"--icon-bg-color": iconBgColor,
		};
	}

	// render the component
	return (
		<div {...props} className={className} style={props.style}>
			<div className="service-card-icon-wrap">
				<i className={icon}></i>
			</div>
			<div className="service-card-content">
				<h3 className="service-card-title">{title}</h3>
				<p className="service-card-description">
					{description}
				</p>
			</div>
		</div>
	);
}
