// import styles
import "../styles/components/_project-card.scss";

//import components
import BtnPrimary from "./BtnPrimary";

export default function ProjectCard({
	title,
	properties = [],
	primaryBtnText,
	outlineBtnText,
	onPrimaryBtnClick,
	onOutlineBtnClick,
	...props
}) {
	// pass component className
	let className = "rx-project-card";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="project-card-pic">
				<img src={require("../assets/pics/project-pic.png")} alt="" />
			</div>

			<h3 className="project-card-title">{title}</h3>

			<div className="project-card-properties row">
				{properties.map((item, index) => (
					<div
						key={index}
						className="project-card-property col-12 col-md-6"
					>
						<span className="project-card-property-title">
							<i
								className={`project-card-property-icon isax ${
									item.icon || ""
								}`}
							></i>
							{item.title}:
						</span>
						<span className="project-card-property-value">
							{item.value}
						</span>
					</div>
				))}
			</div>

			{(primaryBtnText || outlineBtnText) && (
				<div className="project-card-btns">
					{primaryBtnText && (
						<BtnPrimary className="project-card-btn" onClick={onPrimaryBtnClick}>
							{primaryBtnText}
						</BtnPrimary>
					)}

					{outlineBtnText && (
						<BtnPrimary outline className="project-card-btn" onClick={onOutlineBtnClick}>
							{outlineBtnText}
						</BtnPrimary>
					)}
				</div>
			)}
		</div>
	);
}
