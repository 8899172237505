// import styles
import { useEffect } from "react";
import "../../styles/components/decorations/_decoration3.scss";

export default function Decoration3({ size = 202, ...props }) {
	// pass component className
	let className = "rx-decoration-3";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// move elements on mouse move
	useEffect(() => {
		const path2 = document.querySelector(
			`.rx-decoration-3 path:nth-child(2)`
		);
		const path3 = document.querySelector(
			`.rx-decoration-3 path:nth-child(3)`
		);
		const path4 = document.querySelector(
			`.rx-decoration-3 path:nth-child(4)`
		);

		document.addEventListener("mousemove", (event) => {
			let sensitivity; // Adjust the sensitivity to control the amount of movement.

			//set sensitivity based on screen size
			if (window.innerWidth < 768) {
				sensitivity = 0.02;
			} else if (window.innerWidth < 1400) {
				sensitivity = 0.015;
			} else if (window.innerWidth < 2000) {
				sensitivity = 0.01;
			} else if (window.innerWidth < 8000) {
				sensitivity = 0.005;
			} else {
				sensitivity = 0.001;
			}

			const mouseX = event.clientX;
			const mouseY = event.clientY;
			const boxX = window.innerWidth / 2 - mouseX;
			const boxY = window.innerHeight / 2 - mouseY;

			path2.style.transform = `translate(${boxX * sensitivity * 0.9}px, ${
				boxY * sensitivity * 1.2
			}px)`;

			path3.style.transform = `translate(${boxX * sensitivity * 2.2}px, ${
				boxY * sensitivity * 2.5
			}px)`;

			path4.style.transform = `translate(${boxX * sensitivity * 1.6}px, ${
				boxY * sensitivity * 0.9
			}px)`;
		});

		return () => {
			document.removeEventListener("mousemove", () => {});
		};
	}, []);

	let aspectRatio = 202 / 210;
	let width = size;
	let height = size / aspectRatio;

	// render the component
	return (
		<div {...props} className={className}>
			<svg
				width={width}
				height={height}
				viewBox="0 0 202 210"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M79.1692 43.9647C92.4152 36.3171 108.139 34.2144 122.882 38.1191C137.625 42.0239 150.179 51.6162 157.782 64.7858C173.615 92.2095 164.248 129.861 136.64 145.801C109.032 161.74 69.9322 148.188 54.0992 120.765C38.2661 93.341 51.5867 59.8895 79.1692 43.9647Z"
					fill="#E1E8EB"
				/>
				<path
					d="M27.8319 90.8567C27.8319 90.8567 84.6702 111.724 95.1095 107.013C105.549 102.303 72.7267 70.9801 31.7709 65.6239C-17.7888 59.1579 3.95037 83.695 27.8319 90.8567Z"
					fill="#FF7C04"
				/>
				<path
					d="M58.6575 56.786C58.6575 56.786 89.7294 66.7852 95.2533 64.0011C100.777 61.2171 82.7681 42.3762 60.217 43.1065C33.2661 43.9799 45.6291 53.4533 58.6575 56.786Z"
					fill="#0EA5E9"
				/>
				<path
					d="M171.296 101.28C158.215 78.6243 129.139 70.9233 106.353 84.0793C83.5657 97.2353 75.697 126.266 88.7772 148.922C101.857 171.577 130.933 179.278 153.72 166.122C176.507 152.966 184.376 123.935 171.296 101.28Z"
					stroke="#343A40"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
