import React, { useState } from "react";

// import styles
import "../styles/components/_inputs.scss";

export default function InputPrimary({
	type = "text",
	placeholder,
	icon,
	required,
	...props
}) {
	// State to manage the input value
	const [inputValue, setInputValue] = useState("");

	// Event handler to update the input value
	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	// Determine whether to show the placeholder
	const showPlaceholder = !inputValue;

	// pass component className
	let className = "rx-input-wrapper";
	if (props.className) {
		className += ` ${props.className}`;
	}

	// render the component
	return (
		<div {...props} className={className}>
			<div className="rx-input-primary">
				{placeholder && (
					<label
						className={`rx-input-placeholder${
							showPlaceholder ? " show" : ""
						}`}
					>
						{placeholder}
						{required && (
							<span className="rx-input-required">*</span>
						)}
					</label>
				)}

				<input
					className={
						"rx-input" +
						(icon ? " with-icon" : "")
					}
					type={type}
					value={inputValue}
					onChange={handleInputChange}
				/>

				{icon && <i className={`rx-input-icon isax ${icon}`}></i>}
			</div>
		</div>
	);
}
