import OurBenefitsSlider from "./OurBenefitsSlider";
import "../../styles/pages/shared/_our-benefits-section.scss";

export default function OurBenefitsSection() {
	const benefits = [
		{
			icon: "isax isax-timer-1",
			title: "عملکرد سریع و قیمت مناسب",
			description:
				"شرکت ما با استفاده از روش‌هایی که همراه با سرعت و کارایی بالا هستند، توسعه پروژه‌ها را به صورت سریع و با هزینه‌ای مناسب انجام می‌دهد.",
		},
		{
			icon: "isax isax-people",
			title: "تیم مجرب",
			description:
				"تیم حرفه‌ای و مجرب شرکت ما، تضمین می‌کند که پروژه‌های شما به بهترین شکل ممکن انجام شوند. تخصص و تجربه اعضای تیم به عنوان یکی از دستاوردهای ماست.",
		},
		{
			icon: "isax isax-shield-tick",
			title: "امنیت",
			description:
				"ما به امنیت اطلاعات مشتریان و نرم افزار ها بسیار اهمیت می‌دهیم. به همین دلیل، از ابزارها و تکنولوژی‌های امنیتی پیشرفته استفاده می‌کنیم تا اطلاعات و آینده شما را محافظت کنیم.",
		},
		{
			icon: "isax isax-medal-star",
			title: "رعایت استانداردها",
			description:
				"در شرکت ما، رعایت استانداردهای صنعتی و بین‌المللی برای انجام پروژه‌ها الزامی است. با رعایت این استانداردها، اطمینان حاصل می‌شود که پروژه‌های شما با کیفیت بالا و با استفاده از بهترین روش‌ها انجام می‌شوند.",
		},
		{
			icon: "isax isax-support",
			title: "پشتیبانی ۷/۲۴",
			description:
				"تیم پشتیبانی ما طول شبانه‌روز در خدمت شماست. در صورت بروز هرگونه مشکل یا سوال، می‌توانید به ما مراجعه کرده و در اسرع وقت پاسخگویی و رفع مشکل شما را تجربه کنید.",
		},
		{
			icon: "isax isax-code",
			title: "استفاده از تکنولوژی‌های مدرن",
			description:
				"ما از جدیدترین تکنولوژی‌ها و ابزارهای روز دنیا در انجام پروژه‌ها استفاده می‌کنیم. این امکان را فراهم می‌کند که پروژه‌های شما با استفاده از بهترین و مدرن‌ترین ابزارها و تکنولوژی‌ها پیاده‌سازی شوند.",
		},
		{
			icon: "isax isax-path",
			title: "طراحی اختصاصی",
			description:
				"هر پروژه را با توجه به نیازها و خواسته‌های شما طراحی و پیاده‌سازی می‌کنیم. هدف ما ارائه راهکارهایی است که به طور کامل با نیازهای شما سازگاری داشته باشند و از طراحی اختصاصی بهره‌برده شود.",
		},
		{
			icon: "isax isax-lamp-on",
			title: "نوآوری و خلاقیت",
			description:
				"در شرکت ما، به دنبال راهکارها و ایده‌های نوین هستیم. تیم ما با استفاده از خلاقیت و نوآوری در انجام پروژه‌ها، در بهترین عملکرد و زیبایی را به شما ارائه می‌دهد.",
		},
	];

	return (
		<div className="our-benefits-section">
			<div className="our-benefits-header-wrap content-wrapper-lg">
				<div className="our-benefits-header">
					<h2 className="our-benefits-title">
						<span className="bold-text">مزایای</span> رکسومین
					</h2>
				</div>
			</div>

			<div className="our-benefits-content content-wrapper-lg no-transition">
				<OurBenefitsSlider items={benefits} />
			</div>
		</div>
	);
}
